import React from 'react'
import styled from 'styled-components'
import MainGrid from '../styling/MainGrid'
import theme from '../styling/theme'

interface IImageAndText {
  tag?: string
  heading?: string
  body?: Array<string>
  img?: string
  imgAlt?: string
  flip?: boolean
}
const ImageAndTextColumns = ({
  img,
  imgAlt,
  tag,
  heading,
  body,
  flip,
}: IImageAndText) => {
  return (
    <MainGrid>
      <Container flip={flip}>
        <Text>
          {tag && <p className="section-tag">{tag}</p>}
          {heading && <h2>{heading}</h2>}

          {body &&
            body.map((item, key) => {
              return (
                <p key={key} className="p-large">
                  {item}
                </p>
              )
            })}
        </Text>
        <ImageContainer>
          <Img src={img} imgAlt={imgAlt} />
        </ImageContainer>
      </Container>
    </MainGrid>
  )
}

export default ImageAndTextColumns

const Container = styled.section`
  display: flex;
  flex-direction: column;
  padding: 50px 0 25px;
  @media (min-width: 1024px) {
    flex-direction: row;
    flex-direction: ${props => (props.flip ? 'row-reverse' : 'row')};
    justify-content: space-between;
    align-items: center;
    padding: 5rem 0 5rem;
  }
`

const Text = styled.div`
  width: 100%;

  @media (min-width: 1024px) {
    width: 48%;
  }

  & .section-tag {
    margin-bottom: 8px;
  }
  & h2 {
    margin-bottom: 22px;
  }
  & .p-large {
    margin-bottom: 20px;
  }
`

const ImageContainer = styled.div`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  @media (min-width: 768px) {
    max-width: 60%;
  }
  @media (min-width: 1024px) {
    width: 45%;
    margin: 0;
  }
`
const Img = styled.img`
  width: 100%;
`
